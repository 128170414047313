import React, {useEffect, useState} from 'react'


const styles = {
    backGround:{
        position: 'absolute' as 'absolute',
        backgroundColor: "black",
        width: '100%',
        height: '100%',
        zIndex: 1
    },
    container : {
        border: '1px solid black',
        display:'flex',
        flexDirection:'column' as 'column',
        alignItems: 'center',
        position: 'fixed' as 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        height: '400px',
        backgroundColor:'white',
        borderRadius:'10px',
        zIndex:2
    },
    input:{
       opacity:0
    }
}


export const ModalforScan = () => {

    const [data, setData] = useState('');
    const [scannerInfo, setScannerInfo] = useState<any[]>([]);

    const parseData = async () => {

        setScannerInfo(scannerInfo.concat([data]));

    }

    useEffect(()=> {
        if (data){
            let timer1 = setTimeout(() =>{
                parseData();
            } , 1000);
            return () => {
                clearTimeout(timer1);
            };
        }
    },[data])

    return(
        <div className={'background'} style={styles.backGround}>
            <div style={styles.container} className={'container'}>
                <div className={'top'}>
                    <h2>Scanner TEST</h2>
                </div>
                <div className={'body'}>
                    <input style={styles.input} autoFocus onChange={(e)=>setData(e.target.value)} className={'capture'} type={'text'}/>
                    {scannerInfo && scannerInfo.map((item)=>{
                        return(
                            <>
                                <p>{`CODE:${item}`}</p>
                            </>
                        )
                    })
                    }
                    {!scannerInfo && data ? <p>READING</p> : null }
                </div>
                <div className={'footer'}>

                </div>
            </div>
        </div>
    )
}
import React, {useEffect, useState} from 'react';


const styles = {

    backGround:{
        position: 'absolute' as 'absolute',
        backgroundColor: "black",
        width: '100%',
        height: '100%',
        zIndex: 1
    },
    container : {
        border: '1px solid black',
        display:'flex',
        flexDirection:'column' as 'column',
        alignItems: 'center',
        position: 'fixed' as 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        height: '400px',
        backgroundColor:'white',
        borderRadius:'10px',
        zIndex:2
        },
    input:{
       opacity:0
    }
}


export const ModalforCard = () => {

    const [data, setData] = useState('');
    const [cardInfo, setCardInfo] = useState<any[]>([]);

    // const parseData = async () => {
    //     let myHeaders = new Headers();
    //     myHeaders.append("Content-Type", "application/json");
    //     let body = {
    //         text:data
    //     }
    //
    //     let card = await fetch('http://localhost:8088/card/decode',{
    //         method:'POST',
    //         headers: myHeaders,
    //         body:JSON.stringify(body)
    //     });
    //     let card1 = await card.json();
    //     // @ts-ignore
    //     setCardInfo(cardInfo.concat([card1]));
    //
    // }

    useEffect(()=> {
        if (data){
        let timer1 = setTimeout(() =>{
         //   parseData();
        } , 1000);
        return () => {
            clearTimeout(timer1);
        };
        }
    },[data])

    return(
        <div className={'background'} style={styles.backGround}>
            <div style={styles.container} className={'container'}>
                <div className={'top'}>
                    <h2>Card</h2>
                </div>
                <div className={'body'}>
                    {(cardInfo.length == 0 && data) ? <div style={{textAlign: "center"}}><h2>READING</h2></div> : null }
                    <input style={styles.input} autoFocus onChange={(e)=>setData(e.target.value)} className={'capture'} type={'text'}/>
                    {cardInfo && cardInfo.map((item)=>{
                        return(
                            <>
                            <p>{`Card Number:${item.card_number}`}</p>
                            <p>{`Name Holder:${item.first_name}`}</p>
                            <p>{`Last Name:${item.last_name}`}</p>
                            </>
                        )
                    })
                    }
                </div>
                <div className={'footer'}>

                </div>
            </div>
        </div>
    )
}